import $ from "jquery";

function setupBlock($bl) {
    setupTabHeader($bl);
}

function setupTabHeader($bl) {
    const $tabs = $bl.find(".tab");
    if($tabs.length == 0) {
        return;
    }

    const tabInfos = [];
    $tabs.each(function() {
        const $tab = $(this);
        tabInfos.push({
            id: $tab.data("tabid"),
            title: $tab.data("tabtitle")
        });
    });

    $tabs.hide();

    $bl.prepend('<div class="tabheader"></div>');
    const $tabheader = $bl.find(".tabheader");
    for(const tabInfo of tabInfos) {
        const $button = $('<a></a>');
        $button.attr("data-tabid", tabInfo.id);
        $button.text(tabInfo.title);
        $tabheader.append($button);
    }
    const $tabheaderbuttons = $tabheader.find("a");
    $tabheader.on("click", "a", function() {
        const $headerbutton = $(this);
        const tabid = $headerbutton.data("tabid");

        $tabs.hide();
        $bl.find(".tab[data-tabid="+tabid+"]").show();

        $tabheaderbuttons.removeClass("pressed");
        $headerbutton.addClass("pressed");
    });

    /*
        <div class="tabheader">
        <a class="filter-gl">Geschäftsleitung</a>
        <a class="filter-standort" data-standort="huenenberg">Team Hünenberg</a>
        <a class="filter-standort" data-standort="kuessnacht">Team Küssnacht</a>
        </div>
    */
}

$(function() {
    const $blocks = $(".bl-tabs");
    if($blocks.length == 0) {
        return;
    }

    $blocks.each(function() {
        setupBlock($(this));
    });
});

