import $ from "jquery";

function setupBlock($bl) {
    setupFilter($bl);

    /*
    //Neu 23.11.2023: Kein Standardfilter mehr
    if($bl.data("usefilter") == "1") {
        //Standardfilter: Kundendienst
        $bl.find(".filter-kundendienst").first().trigger("click", [{noanim:true}]);
    }
    */
}

function setupFilter($bl) {
    const $filter = $bl.find(".filter");
    if($filter.length == 0) {
        return;
    }

    const $allButtons = $filter.find("a");

    $filter.on("click", "a", function(ev, options) {
        let animspeed = 200;
        if(options && options.noanim) {
            animspeed = 0;
        }

        const $button = $(this);
        $allButtons.not($button).removeClass("pressed"); //de-press other buttons
        const $items = $bl.find(".item");

        function applyFilter() { 
            $items.show();
            
            if($button.hasClass("filter-kundendienst")) {
                $button.toggleClass("pressed");
                if($button.hasClass("pressed")) {
                    $items.not("[data-kundendienst=1]").hide();
                }
            }
            if($button.hasClass("filter-gl")) {
                $button.toggleClass("pressed");
                if($button.hasClass("pressed")) {
                    $items.not("[data-gl=1]").hide();
                }
            }
            if($button.hasClass("filter-standort")) {
                $button.toggleClass("pressed");
                if($button.hasClass("pressed")) {
                    const standort = $button.data("standort");
                    $items.not("[data-standort*="+standort+"]").hide();
                }
            }
        }

        const $itemCtr = $bl.find(".items");
        $itemCtr.fadeOut(animspeed, function() {
            applyFilter();
            $itemCtr.fadeIn(animspeed);
        });
    });
}

$(function() {
    const $blocks = $(".bl-team");
    if($blocks.length == 0) {
        return;
    }

    $blocks.each(function() {
        setupBlock($(this));
    });
});